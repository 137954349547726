
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=PT+Sans+Narrow:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: Inter, Poppins, Ptsans -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .loader {
  width: 48px;
  height: 48px;
  border: 5px solid #281D49;
  margin: auto;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  }  */


.execution-card{

/* card */
box-shadow: 0px 41px 89px 0px rgba(0, 0, 0, 0.08);
}
.MissionAndVision{
  background-image: url("./assets/about/M&V_bg.png");
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  
}
.services-card{
  filter: drop-shadow(0px 41px 89px rgba(0, 0, 0, 0.08));
}
.gradient-border-button{
  position: relative;
  border: none;
  
  /* border-image: linear-gradient(to right, #1E1C49, #9D2161, #F45634);
  border-image-slice: 1;
  border-radius: 18px; */
}
.gradient-border-button::before{
  content: "";
  position: absolute;
  display: block;
  z-index: -1;
  background:linear-gradient(to top right, #1E1C49, #9D2161, #F45634); 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; 
  margin: -1px;
  border-radius: 8px;
 
  
  
}
.gradient-border-button > span {
  position: relative; /* Ensure text remains above the border */
  z-index: 1; /* Ensure text is above the pseudo-element */
  /* Add gradient text styles here */
}
.gradient-text-button{
  background: linear-gradient(to right, #1E1C49, #9D2161, #F45634);
  border: none;
  color: transparent; /* Make text transparent */
  -webkit-background-clip: text; /* Apply background to text */
  -webkit-text-fill-color: transparent; /* Make text color transparent */
  cursor: pointer;
   /* You can adjust font size as needed */
}
.navbarStyle{
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: linear;
  transition-delay: 50ms;
}
.card-Style{
  /* box-shadow: 6px 8px 42px -3px ; */
  -webkit-transform: translate3d(0,0,0);
  filter: drop-shadow(4px 8px 20px rgba(0,0,0,0.2));
  /* filter: drop-shadow(8px 10px 42px #000000);
  */
}
.contact-Style{
  -webkit-transform: translate3d(0,0,0);
  filter: drop-shadow(4px 8px 20px rgba(0,0,0,0.2));
}
.business-card{
  -webkit-transform: translate3d(0,0,0);
  filter: drop-shadow(4px 4px 20px rgba(0,0,0,0.2));
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: linear;
  transition-delay: 50ms;
}
.business-card:hover{
  scale:0.9;
  /* background-color: #F7F4FF; */
  cursor: pointer;
}
.Productcard-Style{
  -webkit-transform: translate3d(0,0,0);
  
  box-shadow: 0px 41px 89px 0px rgba(0, 0, 0, 0.08);
}
.img-Style{
  -webkit-transform: translate3d(0,0,0);
  filter: drop-shadow(4px 8px 20px rgba(0,0,0,0.1));
}
.about-container{
  -webkit-transform: translate3d(0,0,0);
  filter: drop-shadow(2px 2px 20px rgba(0,0,0,0.01));
}
.input-field{
  -webkit-transform: translate3d(0,0,0);
  filter: drop-shadow(2px 2px 20px rgba(0,0,0,0.1));
}
.formStyle{
  -webkit-transform: translate3d(0,0,0);
  
}
.btn {
  width: 60;
  height: 40px;
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 15px;
  font-family: Georgia;
  color: #ffffffff;
  
  background: #564B77;
  padding: 5px 20px;
  text-decoration: none;
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: linear;
  transition-delay: 50ms;
  
}

.btn:hover {
  background: #ee5e3e;
  scale:1.1;
  text-decoration: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.text-wrap{
  word-wrap: break-word; /* Allow long words to break */
  overflow-wrap: break-word; /* Allow overflowed words to wrap */
  white-space: normal; /* Allow text to wrap onto multiple lines */
  flex-grow: 1; /* Allow the paragraph to grow to fill available space */
}
/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side,#281C49 90%,#0000) 0/calc(100%/3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}
@keyframes l1 {to{clip-path: inset(0 -34% 0 0)}}
@keyframes colorChange {
  from{
    background: #564B77;
  }
  to{
    background: #ee5e3e;
  }
}
